.scrollTop {
  position: fixed;
  bottom: 2rem;
  right: 5rem;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  border: 2px solid rgb(255, 255, 255);
  background: #000000;
}

@media only screen and (max-width: 500px) {
  .scrollTop {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 0px 10px;
    height: 10px;
    width: 50px;
    border: 2px solid rgb(255, 255, 255);
    color: aliceblue;
  }
}

.scrollTop:hover {
  border: 2px solid rgb(0, 0, 0);
}
.scrollTop:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #f5f4f2;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

.scrollTop:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.scrollTop .scrollButtonSpan {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 18px 25px;
  color: #fff;
  font-weight: 500;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

.scrollTop:hover .scrollButtonSpan {
  color: #030303;
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
