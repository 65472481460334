/* font defined  */
.landindHeading, .aboutHeading, .aboutSubHeading, .serviceHeading, .serviceSubHeading, .landingSidebarHeading, .sideNavLi, .aboutSidebarHeading, .serviceSidebarHeading, .contactHeading {
  font-family: 'Bebas Neue', sans-serif;
}
.landingPara, .aboutPara, .landingSidebarDescription, .aboutSidebarDescription, .serviceSidebarDescription, .sidemenu{
  font-family: "Open Sans", sans-serif;
}
/* ================================== */
/* Lansding page section  */
#landingpage, #aboutus, #services, #contactus{
  scroll-snap-align: start;
}
.landingpageContainer {
  border-radius: 0.3rem;
  box-shadow: 0 0 5px 5px #00000020;
  backdrop-filter: blur(5px);
  background-color: #ffffff85;
  box-shadow: 0 0 5px 5px #00000020;
  /* backdrop-filter: blur(10px);
  background-color: #00000085; */
}
