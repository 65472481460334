.logobackground {
    background-color: #ededed;
}
#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;
  z-index: 1;
}

#menuToggle .sideNavbarActive {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

#menuToggle .sideNavbarInput {
  display: block;
  width: 10rem;
  height: 5rem;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; 
}
.sideNavbarInput {
    position: fixed;
    top: 4rem;
    left: 1rem;
}
#menuToggle .sideNavbarlogo {
  display: block;
  height: 8px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 3px;
  /* z-index: 1; */
  transform-origin: 8px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}


#menuToggle .sideNavbarInput:checked ~ .sideNavbarlogo {
  opacity: 1;
  transform: translate(0px, -20px);
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  /* margin: -50px 0 0 0px; */
  padding: 50px;
  /* padding-top: 125px; */
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu .sideNavLi {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle .sideNavbarInput:checked ~ .sideNavUl {
  transform: none;
}
