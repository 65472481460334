@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Roboto:wght@100&display=swap");

.footerHeading {
  font-family: "Wotham";
}
.footerSubHeading {
  font-family: "Garamond", sans-serif;
}
.footerSubmitButton {
  padding: 10px 25px;
  font-family: "Didact Gothic", sans-serif;
  font-weight: 500;
  font-size: smaller;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
}

.footerSubmitButton:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.footerSubmitButton:hover {
  color: rgb(0, 0, 0);
}

.footerSubmitButton:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

/* ============================================================= */
/* input field  animation css */
.input-container {
  position: absolute;
  margin: 20px auto;
  width: 290px;
}
#input::placeholder {
  color: #696464;
  font-family: "Didact Gothic", Times, serif;
  text-align: center;
  font-size: smaller;
}
#input:hover::placeholder {
  color: rgb(255, 255, 255);
}
.input-container input[type="email"] {
  font-size: 20px;
  color: transparent;
  text-shadow: 0px 0px 0px #666;
  border: none;
  border-bottom: 2px solid #a5a2a2;
  outline: none;
  width: 100%;
  background-color: transparent;
  padding: 0 20px 0 20px;
}

.input-container .underline {
  position: absolute;
  bottom: 4px;
  left: -7px;
  height: 2px;
  width: 110%;
  background-color: rgb(255, 255, 255);
  transform: scaleX(0);
}

.input-container .sideline {
  position: relative;
  bottom: 15px;
  right: 18px;
  height: 2px;
  width: 18%;
  background-color: rgb(255, 255, 255);
  transform: scaleX(0);
  rotate: 90deg;
}

.input-container .upperline {
  position: absolute;
  bottom: 30px;
  left: -8px;
  height: 2px;
  width: 110%;
  background-color: rgb(255, 255, 255);
  transform: scaleX(0);
}

.input-container .line {
  position: relative;
  bottom: 17px;
  left: 270px;
  height: 2px;
  width: 18%;
  background-color: rgb(255, 255, 255);
  transform: scaleX(0);
  rotate: 90deg;
}

.input-container input[type="email"]:focus ~ .underline,
.input-container input[type="email"]:focus ~ .sideline,
.input-container input[type="email"]:focus ~ .upperline,
.input-container input[type="email"]:focus ~ .line {
  transform: scaleX(1);
  transition: 1s;
}
