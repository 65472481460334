@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Didact+Gothic&family=Roboto:wght@100&display=swap");

/* backgroundvideo  */
#landingVideo {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
/* .Landing Page button css */
.landingPageButton {
  padding: 10px 25px;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  font-weight: 500;
  font-size: smaller;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: rgb(0, 0, 0);
}
.landingpageContainer:hover .landingPageButton {
  color: rgb(255, 255, 255);
}
.landingPageButton:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(10, 10, 10);
  transition: all 0.3s ease;
}
.landingpageContainer:hover .landingPageButton:before {
  top: auto;
  bottom: 0;
  height: 100%;
}
/* ====================================== */
/* side info css */
.landingSideInfo {
  width: 0;
  padding-top: 5%;
  z-index: 3;
  overflow: scroll;
  background-color: #eeeeee;
  transition: ease-in 0.2s;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.landingSideInfo::-webkit-scrollbar {
  display: none; /*  for chrome and safari */
}
#landingoverlay {
  display: none;
  background: #55515165;
}
