.form-main {
  background: linear-gradient(to bottom, #00000024, #00000024), url(/src/Assets/contactbg.jpg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-main .main-wrapper {
  /* border-radius: 10px; */
  padding: 45px;
  width: 60vw;
  border-radius: 0.3rem;
  box-shadow: 0 0 5px 5px #00000020;
  backdrop-filter: blur(5px);
  background-color: #ffffff85;
  box-shadow: 0 0 5px 5px #00000020;
}
@media screen and (max-width: 991px) {
  .form-main .main-wrapper {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .form-main .main-wrapper {
    width: 80vw;
  }
}