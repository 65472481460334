.Services {
  background: url("../../Assets/services.jpg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
}
.servicesBody {
  border-radius: 0.5rem;
  backdrop-filter: blur(5px);
  background-color: rgba(170, 160, 160, 0.522);
  box-shadow: 0 0 5px 5px #00000020;
}
.servicesImageContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 15px;
  gap: 5px;
}
.servicesImageContainer > div {
  border-radius: 5px;
  padding: 8px;
}
.item1,
.item2,
.item3,
.item4,
.item5,
.item6 {
  flex-grow: 1;
  align-self: flex-start;
  width: 8rem;
}
/* hover button  css  */
.servicePageButton {
  padding: 10px 25px;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  font-weight: 500;
  font-size: smaller;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: rgb(0, 0, 0);
}
.servicesBody:hover .servicePageButton {
  color: rgb(255, 255, 255);
}
.servicePageButton:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.servicesBody:hover .servicePageButton:before {
  top: auto;
  bottom: 0;
  height: 100%;
}

/* ================================= */
/*service side bar css */
.ServiceSideinfo {
  width: 0;
  z-index: 3;
  background-color: #eeeeee;
  overflow-x: scroll;
  transition: ease-in 0.5s;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}
.ServiceSideinfo::-webkit-scrollbar {
  display: none; /*  for chrome and safari */
}
#Serviceoverlay {
  display: none;
  background: #55515165;
}
.sidebarDescription {
  width: calc(75% - 50px);
}

/* ================================================= */
/* dropdown info css */
#tanzaniteDescription{
  display: none;
}
#PinkSpineldescription {
  display: none;
}
/* reactmodal css */
.serviceModal {
  border-radius: 1rem;
  position: absolute;
  top: 15vh;
  left: 15vw;
  height: 70vh;
  width: 72vw;
  background: #e6dede;
  overflow: scroll;
  display: flex;
  justify-content: center;  
}
.serviceModal::-webkit-scrollbar {
  display: none;  /*  for chrome and safari */
}
.serviceModal {
  -ms-overflow-style: none;  /* Edge */
  scrollbar-width: none;  /* Firefox */
}