.AboutPage {
  background: url("../../Assets/about.jpg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
}
.Aboutpagebody {
  border-radius: 0.3rem;
  box-shadow: 0 0 5px 5px #00000020;
  backdrop-filter: blur(5px);
  background-color: #ffffff85;
}
/* about page button css */
.aboutPageButton {
  padding: 10px 25px;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  font-weight: 500;
  font-size: smaller;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: rgb(0, 0, 0);
}
.Aboutpagebody:hover .aboutPageButton {
  color: rgb(255, 255, 255);
}
.aboutPageButton:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.Aboutpagebody:hover .aboutPageButton:before {
  top: auto;
  bottom: 0;
  height: 100%;
}
/* =============================== */
.aboutSideInfo{
    width:0;
    padding-top: 5%;
    z-index: 3;
    background-color: #eeeeee;
    transition: ease-in 0.5s ;
}
#aboutoverlay{
    display: none;
    background: #55515165;
} 