@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    user-select: none;
}
html::-webkit-scrollbar{
    display: none;
}
@media(max-width: 425px) {
    html {
        margin-top: -2rem;
    }
}
/* @media(max-width: 292px) {
    html {
        display: none;
    }
} */
@font-face {
    font-family: "Wotham";
    src: local("wotham"),
     url("./Assets/Fonts/Wotham.ttf") format("truetype");
    font-weight: bold;
    font-family: "Garamond";
    src: local("garamond"),
     url("./Assets/Fonts/GaramondPremierProRegular.ttf") format("truetype");
    font-weight: bold;
    
   }